<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Extend Trial</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">

  <!-- Errors -->
  <div *ngIf="errors">
    <div class="alert alert-danger" role="alert" *ngFor="let key of objectKeys(errors)">
      {{errors[key]}}
    </div>
  </div>

  <p>Are you sure you want to extend the trial for <strong>{{customer?.fname}} {{customer?.lname}}</strong>?</p>

  <!-- Extend Trial Form -->
  <form>
    <input type="hidden" [(ngModel)]="customer.guid" name="guid" />

    <!-- Current Trial End Date -->
    <div class="row">
      <label for="trial_date" class="col-sm-4 col-form-label">Current End Date:</label>
      <div class="col-sm-8">
        <input type="text" readonly class="form-control-plaintext" id="trial_date" value="{{customer?.trial_end_date | date:'d MMM yyyy'}}">
      </div>
    </div>

    <!-- New Trial End Date -->
    <div class="row">
      <label for="new_trial_date" class="col-sm-4 col-form-label">New End Date:</label>
      <div class="col-sm-8">
        <input type="text" readonly class="form-control-plaintext" id="new_trial_date" value="{{newTrialEndDate | date:'d MMM yyyy'}}">
      </div>
    </div>

  </form>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="extendTrial()">Yes</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">No</button>
</div>
