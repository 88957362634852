<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Log Chargeback</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">

  <app-error></app-error>

  <p>Are you sure you want to log a Chargeback on transaction # {{transaction?.trans_num}} in the amount of
    <strong>${{transaction?.amount}}</strong> for {{ customer?.fullname }}?</p>

  <form>
    <!-- Case ID -->
    <div class="form-group row">
      <label for="case_id" class="col-sm-4 col-form-label">Case ID</label>
      <div class="col-sm-8">
        <input class="form-control form-control-sm"
            type="text"
            id="case_id" name="case_id"
            required
            [(ngModel)]="chargebackRequest.case_id"
            [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['case_id']}">
      </div>
    </div>

    <!-- Amount -->
    <div class="form-group row">
      <label for="amount" class="col-sm-4 col-form-label">Amount</label>
      <div class="col-sm-8">
        <input class="form-control form-control-sm"
            type="text"
            id="amount" name="amount"
            required
            [(ngModel)]="chargebackRequest.amount"
            [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['amount']}">
      </div>
    </div>

    <!-- Date -->
    <div class="form-group row">
      <label for="date" class="col-sm-4 col-form-label">Date</label>
      <div class="col-sm-8">
        <div class="input-group input-group-sm">
          <input class="form-control"
                 placeholder="yyyy-mm-dd"
                 required
                 id="date"
                 name="date"
                 [(ngModel)]="chargebackRequest.date"
                 ngbDatepicker #d="ngbDatepicker"
                 [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['date']}">
          <button (click)="d.toggle()" type="button" class="btn btn-outline-secondary">
            <i class="fa-regular fa-calendar" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- User -->
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">User</label>
      <div class="col-sm-8">
        {{user.name}}
      </div>
    </div>

    <!-- Cancelled -->
    <div class="form-group row">
      <label for="is_cancelled" class="col-sm-4 col-form-label">Cancelled</label>
      <div class="col-sm-8">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input class="form-check-input"
                   type="radio"
                   name="is_cancelled"
                   id="is_cancelled"
                   [value]="1"
                   [(ngModel)]="chargebackRequest.is_cancelled"
                   [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['card_type']}"> Yes
          </label>
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input class="form-check-input"
                   type="radio"
                   name="is_cancelled"
                   id="is_cancelled2"
                   [value]="0"
                   [(ngModel)]="chargebackRequest.is_cancelled"
                   [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['card_type']}"> No
          </label>
        </div>
      </div>
    </div>

    <!-- Card Type -->
    <div class="form-group row">
      <label for="card_type" class="col-sm-4 col-form-label">Card Type</label>
      <div class="col-sm-8">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input class="form-check-input"
                   type="radio"
                   name="card_type"
                   id="card_type"
                   value="Visa"
                   [(ngModel)]="chargebackRequest.card_type"
                   [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['card_type']}"> Visa
          </label>
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input class="form-check-input"
                   type="radio"
                   name="card_type"
                   id="card_type2"
                   value="Mastercard"
                   [(ngModel)]="chargebackRequest.card_type"
                   [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['card_type']}"> Mastercard
          </label>
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input class="form-check-input"
                   type="radio"
                   name="card_type"
                   id="card_type3"
                   value="Debit"
                   [(ngModel)]="chargebackRequest.card_type"
                   [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['card_type']}"> Debit
          </label>
        </div>
      </div>
    </div>

    <!-- Notes -->
    <div class="form-group row">
      <label for="body" class="col-sm-4 col-form-label">Note</label>
      <div class="col-sm-8">
        <textarea
            class="form-control form-control-sm"
            id="body"
            name="body"
            rows="3"
            required
            [(ngModel)]="chargebackRequest.note"
            [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['note']}"></textarea>
      </div>
    </div>

  </form>

  <!--<pre>{{chargebackRequest | json}}</pre>-->

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="logChargeback()">Yes</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">No</button>
</div>




