import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {HttpErrorResponse} from '@angular/common/http';
import {TransactionInterface} from '../../models/transaction.interface';
import {ChargebackService} from '../chargeback.service';
import {User} from 'src/app/shared/models/user.model';
import {ErrorService} from 'src/app/shared/error/error.service';
import {AlertService} from "@ratespecial/core";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ErrorComponent} from 'src/app/shared/error/error.component';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session.state';
import * as CustomerActions from 'src/app/shared/state/customer.actions';
import {CustomerState} from 'src/app/shared/state/customer.state';
import {Customer} from 'src/app/customer/models/customer.model';

@Component({
  selector: 'app-chargeback-modal',
  templateUrl: './chargeback-modal.component.html',
  styleUrls: ['./chargeback-modal.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, NgbInputDatepicker, ErrorComponent],
  providers: [ErrorService],
})
export class ChargebackModalComponent implements OnInit {

  /**
   * The current user
   */
  public user: User;

  /**
   * The Transaction that is the subject of the refund.  Passed in from caller.
   */
  public transaction: TransactionInterface;

  /**
   * Customer Data needed for request
   */
  customer: Customer;

  /**
   * Form Model
   * @type {{guid: any; reason: any; note: any}}
   */
  public chargebackRequest = {
    guid: null, // set by parent modal reference
    customer_status: null,
    trans_num: null,
    note: null,
    date: null,
    amount: null,
    card_type: null,
    is_cancelled: null,
    case_id: null,
  };


  constructor(
    private activeModal: NgbActiveModal,
    private alertService: AlertService,
    private chargebackService: ChargebackService,
    public errorService: ErrorService,
    private store: Store,
  ) {
    // Get current user
    this.store.select(SessionState.getUser)
      .pipe(takeUntilDestroyed())
      .subscribe(user => {
        this.user = user;
        this.chargebackRequest.note = `Chargeback logged by ${this.user.name}`;
      });

    // For whom.  This will be updated through ngOnInit() due to inputs
    this.store.select(CustomerState.getCustomer)
      .pipe(takeUntilDestroyed())
      .subscribe(cust => {
        this.customer = cust;
        this.chargebackRequest.customer_status = this.customer.status;
      });
  }

  ngOnInit() {
    // Ensure we're on the correct customer.  As of now this modal can only be accessed from the detail screen, so this operation should
    // be instant and free.
    this.store.dispatch(new CustomerActions.UpdateCustomer(this.chargebackRequest.guid))

    // this.transaction is passed in from caller
    this.chargebackRequest.amount = this.transaction.amount;
    this.chargebackRequest.trans_num = this.transaction.trans_num;
  }

  /**
   * Send chargeback request to server
   */
  logChargeback(): void {
    this.errorService.clearErrors();

    this.chargebackService.refund(this.chargebackRequest)
      .subscribe({
        next: () => {
          this.alertService.success('Chargeback initiated');
          this.close();
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.handleValidationErrors(err);
        },
      });
  }

  close() {
    this.activeModal.close();
  }
}
