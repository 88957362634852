<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Reactivate Account</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">

  <app-error></app-error>

  <p>Are you sure you want to reactivate the account for <strong>{{customer?.fname}} {{customer?.lname}}</strong>?</p>

  <form>

    <input type="hidden" name="guid" [(ngModel)]="reactivateAccountRequest.guid">

    <!-- Notes -->
    <div class="form-group row">
      <label for="body" class="col-sm-4 col-form-label">Note</label>
      <div class="col-sm-8">
        <textarea
          class="form-control"
          id="body"
          name="body"
          rows="3"
          [(ngModel)]="reactivateAccountRequest.note"
          [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['note']}"></textarea>
      </div>
    </div>

  </form>

  <!--<pre>{{customer|json}}</pre>-->
  <!--<pre>{{reasons|json}}</pre>-->
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="reactivateAccount()">Yes</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">No</button>
</div>




