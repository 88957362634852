<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Email Offer</h5>
<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">
  <p>Select one of the predefined email templates below to send to the customer.</p>

  <form>
    <input type="hidden" name="customer_guid" value="B6BFF7AD20B62A4617D29DBD09DDC9B9">

    <!-- Email Template -->
    <div class="form-group row">
      <label for="channel_type" class="col-sm-4 col-form-label">Email Template</label>
      <div class="col-sm-8">
        <select class="form-control"
            id="channel_type"
            name="channel_type"
            [(ngModel)]="emailOfferRequest.email_id"
            required [ngClass]="{'is-invalid' : errors && errors['type']}">
          <option value="3">UKCreditRatings.com Follow Up</option>
        </select>
      </div>
    </div>

  </form>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="save()">Yes</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">No</button>
</div>
