<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">One Time Password</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.close()"></button>
</div>

<div class="modal-body">
  <p class="main-text">
    @if (provider === 'Email') {
      This will email a one time password to the customer at <br> <strong>{{customer.email}}</strong>
    } @else {
      This will text a one time password to the customer at <br> <strong>{{customer.phone | ukPhone}}</strong>
    }
  </p>

  <div class="provider-container">
    <p class="provider-item">
      <span class="fw-bold">Delivery method</span> <br>
      <span class="small">Go with the default unless they don't receive the message in a timely fashion.</span>
    </p>

    <div class="provider-radios provider-item">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="provider" value="TW" id="twilio" [(ngModel)]="provider">
        <label class="form-check-label" for="twilio">
          SMS: Twilio
        </label>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="radio" name="provider" value="ES" id="esendex" [(ngModel)]="provider">
        <label class="form-check-label" for="esendex">
          SMS: Esendex
        </label>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="radio" name="provider" value="Email" id="email" [(ngModel)]="provider">
        <label class="form-check-label" for="email">
          Email
        </label>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="send()">Send</button>
</div>
