<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Send SMS</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">

  <app-error></app-error>

  <p>Enter message for <strong>{{customer?.fname}} {{customer?.lname}}</strong>.</p>

  <form>

    <input type="hidden" name="guid" [(ngModel)]="sendSmsRequest.guid">

    <!-- Reason -->
    <div class="form-group row">
      <label for="reason" class="col-sm-4 col-form-label">Message</label>
      <div class="col-sm-8">
        <textarea
            name="message"
            id="message"
            required
            class="form-control"
            [(ngModel)]="sendSmsRequest.message"
            [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['message']}"
        >
        </textarea>
      </div>
    </div>

  </form>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="sendSms()">Send</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Cancel</button>
</div>




